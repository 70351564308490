<template>
  <div>
    <div class="application">
      <div class="line showpc" style="height:60px;border-bottom:solid 1px var(--C3);">
        <div class="line-33">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:left;margin-left:15px;">
            <li style="line-height:60px;">
              <img src="media/logo_astuce.jpg" alt="" style="height:50px;margin-top:5px;">
            </li>
          </ul>
        </div>
        <div class="line-34">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:center;">
            <li style="line-height:60px;">
              <img src="media/logo.png" alt="" style="cursor:pointer;height:50px;margin-top:5px;" @click="navigation('board')">
            </li>
          </ul>
        </div>
        <div class="line-33">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:right;margin-right:15px;">
            <li style="line-height:60px;display:inline;float:left;width:85%">
              <button class="fbutton" @click="navigation('board')">Accueil</button>
            </li> 
            <li style="line-height:60px;display:inline;width:50px;">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div class="line showmobile" style="height:60px;border-bottom:solid 1px var(--C3);">
        <div class="line-50">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:left;margin-left:15px;">
            <li style="line-height:60px;">
              <img src="media/logo.png" alt="" style="height:50px;margin-top:5px;">
            </li>
          </ul>
        </div>
        <div class="line-50">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:right;margin-right:15px;">
            <li style="line-height:60px;">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div class="line" style="text-align:center;">      
        <h1>Vos contrats de location</h1>
        <h3>Historique de vos contrats de locations</h3>
      </div>
      <div class="line-m" style="margin-top:40px;">
        <table class="ftable">
          <tr>
            <th>Numéro de contrat</th>
            <th>Date de début</th>
            <th>Date de fin</th>
            <th>Numéro de facture</th>
            <th>Type de vélo</th>
          </tr>
          <tr v-for="rental in list" :key="rental.id">
            <td>{{rental.rental_number}}</td>
            <td>{{rental.rental_start_date|moment('DD/MM/YYYY')}}</td>
            <td>{{rental.rental_end_date|moment('DD/MM/YYYY')}}</td>
            <td>{{rental.invoice_number}}</td>
            <td>{{rental.descrip}}</td>
          </tr>
        </table>
      </div>
    </div>
    <loadingform v-if="loading"></loadingform>
  </div>
</template>

<script>
import engine from "../core/DataAPI";
import login from "../core/IconLogin";
import loadingform from "../core/LoadingForm";

export default {
  components:{
    login,loadingform
  },
  data() {
    return {
      loading:false,
      account:{},
      vdate:'',
      list:[]
    }
  },
  created: function(){
    var tmain = this;
    this.$moment.locale('fr');
    tmain.loading=true;
    engine.Account_Info(function(pkg){
      if (pkg.code!=0){
        tmain.loading=false;
        tmain.$router.push('/');
      }else{
        tmain.account = pkg.data[0];
        engine.Rentals_List(function(pkgrental){
          tmain.loading=false;
          if (pkgrental.code==0){
            tmain.list = pkgrental.data;
          }
        });
      }
    });  
  },
  methods: {
    donwloadRental: function(rental) 
    {
      engine.File_Rental_Download(rental.rental_number,function(pkgfile){
        var extname = ".pdf";
        var fileURL = window.URL.createObjectURL(new Blob([pkgfile.data]));
        var download_link = document.createElement('a');
        download_link.href = fileURL;
        download_link.download = rental.rental_number + extname;
        download_link.target = "_blank";
        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);
      });
    },
    navigation:function(app){
      var tmain = this;
      tmain.$router.push('/'+app);
    }
  }
}
</script>
<style>
</style>
