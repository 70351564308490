<template>
    <div style="position:absolute;height:100%;width:100%;top:0px;left:0px;">
        <div style="position:absolute;height:100%;width:100%;top:0px;left:0px;background-color:#fff;opacity:0.8;">

        </div>
        <div style="position:absolute;width:100%;top:calc(50% - 100px);left:0px;text-align:center;">
            <i style="color:var(--BUTTON);" class='fas fa-circle-notch fa-spin fa-4x'></i>
            <h1 style="color:var(--C2);">Veuillez patienter...</h1>
        </div>
    </div>
</template>

<script>

export default {
    
    props:{
        
    },
    data(){
        return {
            
        };
    },
    created() {
        
    },
    methods:{
        
    }
}
</script>

<style>
</style>