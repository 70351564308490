<template>
  <div>
    <div class="application">
      <div class="line showpc" style="height: 60px; border-bottom: solid 1px var(--C3)">
        <div class="line-33">
          <ul style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: left;
              margin-left: 15px;
            ">
            <li style="line-height: 60px">
              <img src="media/logo_astuce.jpg" alt="" style="height: 50px; margin-top: 5px" />
            </li>
          </ul>
        </div>
        <div class="line-34">
          <ul style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: center;
            ">
            <li style="line-height: 60px">
              <img src="media/logo.png" alt="" style="cursor: pointer; height: 50px; margin-top: 5px"
                @click="backAccount" />
            </li>
          </ul>
        </div>
        <div class="line-33">
          <ul style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: right;
              margin-right: 15px;
            ">
            <li style="
                line-height: 60px;
                display: inline;
                float: left;
                width: 85%;
              ">
              <button class="fbutton" @click="backAccount">Accueil</button>
            </li>
            <li style="line-height: 60px; display: inline; width: 50px">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div class="line showmobile" style="height: 60px; border-bottom: solid 1px var(--C3)">
        <div class="line-50">
          <ul style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: left;
              margin-left: 15px;
            ">
            <li style="line-height: 60px">
              <img src="media/logo.png" alt="" style="height: 50px; margin-top: 5px" @click="backAccount" />
            </li>
          </ul>
        </div>
        <div class="line-50">
          <ul style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: right;
              margin-right: 15px;
            ">
            <li style="line-height: 60px">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="viewdiv == 'reservation'">
        <div class="line" style="text-align: center">
          <h1>Nouvelle réservation</h1>
          <h3>Effectuez votre réservation en ligne</h3>
        </div>
        <div class="line-a" style="margin-top: 40px; margin-bottom: 40px">
          <div v-if="step >= 1" class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 1 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez le type de vélo que vous souhaitez louer
              </p>
            </div>
            <ul class="family-bloc-text">
              <li v-for="material in list_materials" :key="material.id" @click="validS1(material)">
                <div class="family-bloc-title">{{ material.titre1 }}</div>
                <div v-if="material.qty > 0">
                  <div class="family-bloc-icon">
                    <v-lazy-image :src="'./media/velo_' + material.id + '.png'"
                      :src-placeholder="'./media/velo_' + material.id + '.png'" alt=""
                      :class="{ 'border-bloc-selected': material.selected }" style="height: 100px" />
                    <!--<img :src="'./media/velo_'+material.id+'.jpg'" alt="" :class="{'border-bloc-selected' : (material.selected)}" style="height:100px;">-->
                  </div>
                  <div class="family-bloc-legend">{{ material.titre2 }}</div>
                </div>
                <div v-if="material.qty <= 0">
                  <div class="family-bloc-icon" @click="goViewNOSTOCK(material)">
                    <v-lazy-image :src="'./media/velo_' + material.id + '.png'"
                      :src-placeholder="'./media/velo_' + material.id + '.png'" alt=""
                      :class="{ 'border-bloc-selected': material.selected }"
                      style="height: 100px; filter: opacity(0.3)" />
                    <!--<img :src="'./media/velo_'+material.id+'.jpg'" alt="" :class="{'border-bloc-selected' : (material.selected)}" style="height:100px;filter:grayscale(1);">-->
                  </div>
                  <div class="family-bloc-legend" @click="goViewNOSTOCK(material)">
                    <b>Rupture de stock</b> - {{ material.titre2 }}
                  </div>
                </div>
                <div class="family-bloc-select"></div>
              </li>
            </ul>
            <div id="blocacc" v-if="JSON.stringify(material_selected) != '{}' &&
              material_selected.accessories.length > 0
            " class="account-bloc-title" style="border-top: solid 1px var(--C3)">
              <label style="font-size: 18px">Accessoires</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez les accessoires au nombre de
                {{ material_selected.qtymaxaccessories }} au maximum
              </p>
            </div>
            <ul v-if="JSON.stringify(material_selected) != '{}' &&
              material_selected.accessories.length > 0
            " class="accessory-bloc-text">
              <li v-for="acc in material_selected.accessories" :key="acc.id">
                <div class="family-bloc-title">{{ acc.label }}</div>
                <!--<div class="family-bloc-icon"><img :src="'./media/'+acc.logo+'.png'" alt="" style="height:100px;"></div>-->
                <ul v-if="acc.qties.length > 1" class="accessory-bloc-qty">
                  <li v-for="q in acc.qties" :key="q.value" @click="validS1acc(acc, q)">
                    <label :class="{ 'border-bloc-selected': q.selected == true }">{{ q.value }}</label>
                  </li>
                </ul>
                <ul v-if="acc.qties.length == 1" class="accessory-bloc-qty">
                  <li v-for="q in acc.qties" :key="q.value" @click="validS1acc(acc, q)">
                    <label>Rupture de stock</label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div id="bloc2" v-if="step >= 2" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 2 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez la durée de location souhaitée (en nombre de mois)
              </p>
            </div>
            <div class="account-bloc-text" style="padding-top: 10px; padding-bottom: 10px">
              <ul class="accessory-bloc-qty" style="margin-left: 25px">
                <li v-for="q in list_mois" :key="q.value" @click="validS2(q)">
                  <label :class="{ 'border-bloc-selected': q.selected == true }">{{ q.value }}</label>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div id="bloc3" v-if="step >= 3" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 3 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez le type d'abonnement
              </p>
              <!--<br>
                <input type="checkbox" id="assurance" v-model="assurance" style="float:left;" @click="modifAssur(assurance)">
                <label for="assurance" style="font-size:13px;color:var(--C2);margin-left:4px;float:left;">J'ai déjà une assurance</label>
                <br><br>
                <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;">Pour louer un vélo, la souscription d’une assurance  incluant la responsabilité civile et le vol est obligatoire. Vous pouvez y renoncer si vous justifiez d’une attestation d’assurance tierce comprenant les mêmes garanties.</p>-->
            </div>
            <div v-for="subscription in material_selected.subscriptions" v-bind:key="subscription.id" :class="{
              'account-bloc-text': true,
              'bloc-selected': subscription.selected == true,
            }" @click="validS3(subscription)">
              <div class="account-bloc-legend">
                ABONNEMENT {{ subscription.libelle }}
              </div>
              <div class="account-bloc-value" style="font-size: 12px">
                {{ subscription.description }}
              </div>
              <div class="account-bloc-icon">
                <fa-icon v-if="!(subscription.selected == true)" :icon="['far', 'circle']" />
                <fa-icon v-if="subscription.selected == true" :icon="['far', 'check-circle']" />
              </div>
            </div>
          </div>
        </div>

        <div id="bloc4" v-if="step >= 4" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 4 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez le type de règlement
              </p>
            </div>
            <div v-for="pric in subscription_selected.prices" :key="pric.id" :class="{
              'account-bloc-text': true,
              'bloc-selected': pric.selected == true,
            }" @click="validS4(pric)">
              <div class="account-bloc-legend">{{ pric.label }}</div>
              <div class="account-bloc-value" style="font-size: 12px">
                {{ pric.description }}
              </div>
              <div class="account-bloc-icon" style="text-align: left; padding: 20px 20px 20px 0px">
                <label v-if="pric.type == 4" style="font-weight: bold">Payez maintenant : {{ list_prices[1].amount |
                  money
                  }}</label>
                <label v-if="pric.type == 1" style="font-weight: bold">Payez maintenant : {{ list_prices[0].amount |
                  money
                  }}</label>
                <br />
                <label style="font-size: 13px">Prix total : {{ list_prices[0].total_amount | money }}</label>
              </div>
            </div>
          </div>
        </div>

        <div id="bloc5" v-if="step >= 5" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 5 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Lieu de retrait, de maintenance et de rendu du vélo
              </p>
            </div>
            <div v-for="agencie in list_agencies" :key="agencie.id" :class="{
              'account-bloc-text': true,
              'bloc-selected': agencie.selected == true,
            }" @click="validS5(agencie)">
              <!-- {{ agencie }} -->
              <div class="account-bloc-legend">{{ agencie.Nom }}</div>
              <div class="account-bloc-value" style="font-size: 12px; color: var(--C2)">
                {{ agencie.Adresse }}
                <!-- <div style="float: right; font-size: 15px; font-weight: bold">
                  {{ agencie.price }} €
                </div> -->
              </div>
              <div class="account-bloc-icon">
                <fa-icon :style="{ 'font-size': icon_size + 'px' }" v-if="!(agencie.selected == true)"
                  :icon="['far', 'circle']" />
                <fa-icon :style="{ 'font-size': icon_size + 'px' }" v-if="agencie.selected == true"
                  :icon="['far', 'check-circle']" />
              </div>
            </div>
          </div>
        </div>

        <div id="bloc6" v-if="step >= 6" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 6 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Insérer les documents adminitratifs nécessaires pour la location
              </p>
            </div>
            <div v-if="subscription_selected.id >= 1">
              <div v-if="price_selected.type != 4" class="account-bloc-text" @click="editprofile">
                <div class="account-bloc-legend">IBAN / BIC</div>
                <div v-if="account.iban == '' || account.bic == ''" style="color: red; font-weight: bold"
                  class="account-bloc-value">
                  Veuillez renseigner votre IBAN et BIC
                </div>
                <div v-if="account.iban != '' && account.bic != ''" class="account-bloc-value">
                  {{ account.iban }} / {{ account.bic }}
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div v-if="piece_cni == true" class="account-bloc-text">
                <div class="account-bloc-legend">
                  CNI / Passeport en cours de validité
                </div>
                <div class="account-bloc-value" @click="downloadFile('cni')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('cni')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_cni == false" class="account-bloc-text" @click="uploadFile('cni')">
                <div class="account-bloc-legend">
                  CNI / Passeport en cours de validité
                </div>
                <div class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div v-if="piece_justificatif == true" class="account-bloc-text">
                <div class="account-bloc-legend">
                  Justificatif de domicile de moins de 3 mois
                </div>
                <div class="account-bloc-value" @click="downloadFile('justificatif')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('justificatif')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_justificatif == false" class="account-bloc-text" @click="uploadFile('justificatif')">
                <div class="account-bloc-legend">
                  Justificatif de domicile de moins de 3 mois
                </div>
                <div class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div v-if="piece_rib == true && price_selected.type != 4" class="account-bloc-text">
                <div class="account-bloc-legend">RIB</div>
                <div class="account-bloc-value" @click="downloadFile('rib')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('rib')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_rib == false && price_selected.type != 4" class="account-bloc-text"
                @click="uploadFile('rib')">
                <div class="account-bloc-legend">RIB</div>
                <div class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <!-- justificatif de scolarité si abonnement : ... -->
              <div v-if="piece_scolarite == true && subscription_selected.id == 2" class="account-bloc-text">
                <div class="account-bloc-legend">Justificatif de scolarite</div>
                <div class="account-bloc-value" @click="downloadFile('scolarite')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('scolarite')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_scolarite == false && subscription_selected.id == 2" class="account-bloc-text"
                @click="uploadFile('scolarite')">
                <div class="account-bloc-legend">Justificatif de scolarite</div>
                <div class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <!-- justificatif de QF si abonnement : ... -->
              <div v-if="piece_qf == true &&
                (subscription_selected.id == 3 ||
                  subscription_selected.id == 5)
              " class="account-bloc-text">
                <div class="account-bloc-legend">
                  Justificatif CAF de QF &#60; 700
                </div>
                <div class="account-bloc-value" @click="downloadFile('caf')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('caf')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_qf == false &&
                (subscription_selected.id == 3 ||
                  subscription_selected.id == 5)
              " class="account-bloc-text" @click="uploadFile('caf')">
                <div class="account-bloc-legend">
                  Justificatif CAF de QF &#60; 700
                </div>
                <div class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <!-- justificatif de PDME-A de la MRN ... si abonnement : ... -->
              <div v-if="piece_pdmea == true &&
                (subscription_selected.id == 4 ||
                  subscription_selected.id == 5)
              " class="account-bloc-text">
                <div class="account-bloc-legend">Justificatif PDME-A</div>
                <div class="account-bloc-value" @click="downloadFile('pdmea')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('pdmea')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_pdmea == false &&
                (subscription_selected.id == 4 ||
                  subscription_selected.id == 5)
              " class="account-bloc-text" @click="uploadFile('pdmea')">
                <div class="account-bloc-legend">Justificatif PDME-A</div>
                <div class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <!-- justificatif d'assurance si pas d'assurance ... -->
              <div v-if="piece_assurance == true && assurance" class="account-bloc-text">
                <div class="account-bloc-legend">Justificatif Assurance</div>
                <div class="account-bloc-value" @click="downloadFile('assurance')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('assurance')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_assurance == false && assurance" class="account-bloc-text"
                @click="uploadFile('assurance')">
                <div class="account-bloc-legend">Justificatif Assurance</div>
                <div class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="bloc7" v-if="step >= 6 &&
          account.is_main_bank == 'non' &&
          price_selected.type != 4
        " class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Informations concernant le titulaire du compte bancaire</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Fournir les informations complémentaires concernant le titulaire
                du compte
              </p>
            </div>
            <div v-if="banq_account.nom == ''" class="account-bloc-text" @click="editbanqaccount">
              <div class="account-bloc-legend">TITULAIRE DU COMPTE</div>
              <div style="color: red; font-weight: bold" class="account-bloc-value">
                <i class="fa fa-info-circle"></i> Veuillez renseigner les
                informations du titulaire du compte
              </div>
              <div class="account-bloc-icon">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
            <div v-if="banq_account.nom != ''" class="account-bloc-text" @click="editbanqaccount">
              <div class="account-bloc-legend">TITULAIRE DU COMPTE</div>
              <div class="account-bloc-value">
                {{ banq_account.prenom }} {{ banq_account.nom }}
              </div>
              <div class="account-bloc-icon">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
            <div v-if="piece_cni_titulaire == true" class="account-bloc-text">
              <div class="account-bloc-legend">
                CNI / Passeport en cours de validité
              </div>
              <div class="account-bloc-value" @click="downloadFile('cni_titulaire')">
                Consulter le document
              </div>
              <div class="account-bloc-icon" @click="uploadFile('cni_titulaire')">
                <i class="fa fa-upload"></i>
              </div>
            </div>
            <div v-if="piece_cni_titulaire == false" class="account-bloc-text" @click="uploadFile('cni_titulaire')">
              <div class="account-bloc-legend">
                CNI / Passeport en cours de validité
              </div>
              <div class="account-bloc-value" style="color: red; font-weight: bold">
                <i class="fa fa-info-circle"></i> Insérer la CNI ou le passeport
                du titulaire du compte bancaire
              </div>
              <div class="account-bloc-icon">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
            <div v-if="piece_attestation == true" class="account-bloc-text">
              <div class="account-bloc-legend">Procuration</div>
              <div class="account-bloc-value" @click="downloadFile('attestation')">
                Consulter le document
              </div>
              <div class="account-bloc-icon" @click="editbanqfile">
                <i class="fa fa-upload"></i>
              </div>
            </div>
            <div v-if="piece_attestation == false" class="account-bloc-text" @click="editbanqfile">
              <div class="account-bloc-legend">Procuration</div>
              <div class="account-bloc-value" style="color: red; font-weight: bold">
                <i class="fa fa-info-circle"></i> Insérer le formulaire de
                procuration complété et signé
              </div>
              <div class="account-bloc-icon">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div id="bloc_recap" v-if="visu_payment" class="line-a" style="margin-bottom: 40px; padding-bottom: 40px">
          <div class="account-bloc">
            <div class="recap-bloc-title">
              <label style="font-size: 18px">Récaptitulatif</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Récapitulatif de votre réservation
              </p>
            </div>
            <div class="recap-bloc-title" style="background-color: var(--BUTTON); border-bottom: none">
              <label style="font-size: 18px; color: #fff"><i class="fa fa-exclamation"></i></label>
              <p style="color: #fff; font-size: 13px; padding: 0px; margin: 0px">
                Félicitations ! Votre réservation sera finalisée après
                règlement.
              </p>
              <p style="color: #fff; font-size: 13px; padding: 0px; margin: 0px">
                Prochaine étape : prendre rendez-vous avec la Station – 78 rue
                Jeanne d’Arc à Rouen – pour signature de votre contrat de
                location et retrait de votre vélo (un e-mail va vous être
                envoyé). Ce dernier vous sera remis lors de votre rendez-vous
                après vérification des pièces justificatives fournies et
                versement de la caution.
              </p>
            </div>
            <div class="recap-bloc-text" style="background-color: var(--C2); color: #fff; border-top: none">
              <div style="
                  padding: 20px;
                  text-align: center;
                  width: calc(100% - 40px);
                  float: left;
                ">
                <label style="font-size: 16px">{{ lbl_description }}</label>
                <br />
                <label style="font-size: 32px">{{ lbl_amount | money }}</label><br />
                <label style="font-size: 12px">{{ lbl_payment_info_cb }}</label><br />
                <label style="font-size: 12px">La caution varie de 366 € à 2700€ (en fonction du type
                  d’abonnement et du matériel)</label>
                <!--<label style="font-size:12px;">la caution s'élèvera a {{lbl_caution_amount|money}}</label>-->
              </div>
            </div>
            <div class="recap-bloc-text" style="background-color: #fff; color: var(--C2); border-top: none">
              <div style="
                  padding: 20px;
                  text-align: center;
                  width: calc(100% - 40px);
                  float: left;
                ">
                <label v-if="acceptcguv" style="font-size: 16px">
                  <fa-icon :icon="['fas', 'check-square']" @click="acceptcguv = !acceptcguv" />
                  J'accepte les
                  <a style="
                      text-decoration: underline;
                      color: var(--BUTTON);
                      font-weight: bold;
                      cursor: pointer;
                    " @click="downloadFile('CGUV')">CGUV</a>
                  et la
                  <a target="_blank" style="color: var(--BUTTON); font-weight: bold"
                    href="https://reseau-astuce.fr/fr/politique-de-protection-des-donnees/188">politique de
                    confidentialité</a>
                </label>
                <label v-if="!acceptcguv" style="font-size: 16px">
                  <fa-icon :icon="['fas', 'square']" @click="acceptcguv = !acceptcguv" />
                  J'accepte les
                  <a target="_blank" style="
                      text-decoration: underline;
                      color: var(--BUTTON);
                      font-weight: bold;
                      cursor: pointer;
                    " @click="downloadFile('CGUV')">CGUV</a>
                  et la
                  <a target="_blank" style="color: var(--BUTTON); font-weight: bold"
                    href="https://reseau-astuce.fr/fr/politique-de-protection-des-donnees/188">politique de
                    confidentialité</a>
                </label>
              </div>
            </div>
            <div class="recap-bloc-text" style="
                padding-top: 20px;
                padding-bottom: 20px;
                text-align: center;
                width: 100%;
              ">
              <div style="
                  padding: 10px;
                  text-align: center;
                  width: calc(100% - 20px);
                  float: left;
                ">
                <button v-if="acceptcguv" @click="payment_start" class="fbutton fbutton-big">
                  Régler la réservation
                </button>
                <button v-if="!acceptcguv" class="fbutton fbutton-big" style="background-color: var(--C2)">
                  Régler la réservation
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="viewdiv == 'retour'">
        <div class="line" style="text-align: center; margin-top: 120px">
          <i v-if="msgretour == 'ok'" style="color: var(--BUTTON)" class="far fa-smile-beam fa-4x"></i>
          <i v-if="msgretour != 'ok'" style="color: var(--BUTTON)" class="far fa-frown fa-4x"></i>
          <h1>{{ msgtitle }}</h1>
          <h3>{{ msgsubtitle }}</h3>
          <button class="fbutton" @click="backAccount">
            Retour à votre compte
          </button>
        </div>
      </div>
      <div v-if="viewdiv == 'nostock'">
        <div class="line" style="text-align: center; margin-top: 120px">
          <i style="color: var(--BUTTON)" class="far fa-frown fa-4x"></i>
          <h1>Rupture de stock</h1>
          <h3>
            Nous n'avons plus de vélo électrique de disponible, veuillez cliquer
            sur le bouton ci-dessous afin de vous inscrire dans notre liste
            d'attente.
          </h3>
          <button style="margin-right: 10px" class="fbutton" @click="goNOSTOCK">
            S'inscrire sur la liste d'attente
          </button>
          <button class="fbutton" @click="backAccount">
            Retour à votre compte
          </button>
        </div>
      </div>
      <div v-if="viewdiv == 'nostock_valid'">
        <div class="line" style="text-align: center; margin-top: 120px">
          <i style="color: var(--BUTTON)" class="far fa-smile-beam fa-4x"></i>
          <h1>Inscription dans la liste d'attente</h1>
          <h3>
            Votre inscription dans la liste d'attente a bien été effectuée.
          </h3>
          <button class="fbutton" @click="backAccount">
            Retour à votre compte
          </button>
        </div>
      </div>
    </div>
    <dataform v-if="JSON.stringify(ctrldataform) != '{}'" :title="ctrldataform.title" :controls="ctrldataform.controls"
      :fxback="ctrldataform.fxback" :fxsave="ctrldataform.fxsave"></dataform>
    <loadingform v-if="loading"></loadingform>
  </div>
</template>

<script>
import engine from "../core/DataAPI";
import login from "../core/IconLogin";
import dataform from "../core/DataForm";
import loadingform from "../core/LoadingForm";

export default {
  components: {
    login,
    dataform,
    loadingform,
  },
  data() {
    return {
      noStockMaterial: undefined,
      loading: false,
      debut_haute: "03-21",
      debut_basse: "09-21",
      acceptcguv: false,
      msgtitle: "",
      msgsubtitle: "",
      msgretour: "",
      account: {},
      vdate: "",
      datenow: "",
      assurance: false,
      viewdiv: "reservation",
      maxstep: 6,
      currentstep: 1,
      piece_cni: false,
      piece_justificatif: false,
      piece_rib: false,
      piece_scolarite: false,
      piece_pdmea: false,
      piece_assurance: false,
      piece_qf: false,
      /*piece_cmuc:false,*/
      /*piece_mobilite:false,*/
      piece_attestation: false,
      piece_cni_titulaire: false,
      duree_loc: 0,
      date_retrait: "",
      delivery_place_selected: undefined,
      date_fin: "",
      montant_tot: 0,
      montant_mois: 1,
      step: 1,
      form_tpe: "6481836",
      form_date: "",
      form_ref: "",
      form_mac: "",
      form_contexte: "",
      form_societe: "tcarlovelo",
      form_mail: "",
      flag_comptant: "",
      list_mois: [
        { value: 1, selected: false },
        { value: 2, selected: false },
        { value: 3, selected: false },
        { value: 4, selected: false },
        { value: 5, selected: false },
        { value: 6, selected: false },
        { value: 7, selected: false },
        { value: 8, selected: false },
        { value: 9, selected: false },
        { value: 10, selected: false },
        { value: 11, selected: false },
        { value: 12, selected: false },
      ],
      list_materials: [],
      list_agencies: [],
      list_prices: [],
      ctrldataform: {},
      banq_account: {
        nom: "",
        prenom: "",
        adresse: "",
        cp: "",
        ville: "",
        email: "",
        telephone: "",
        lien_parente: "",
        id_fichier: engine.Guid(),
      },
    };
  },
  watch: {
    step(val) {
      if (val == 6) {
        this.iban_verification();
      }
    },
  },
  computed: {
    visu_payment: function () {
      var tmain = this;
      var ret = false;

      if (tmain.step >= 6) {
        if (tmain.subscription_selected.id == 1) {
          if (
            tmain.piece_cni &&
            tmain.piece_justificatif &&
            (tmain.piece_rib || tmain.price_selected.type == 4) &&
            (tmain.account.iban != "" || tmain.price_selected.type == 4) &&
            (tmain.is_valid_iban != "" || tmain.price_selected.type == 4) &&
            (tmain.account.bic != "" || tmain.price_selected.type == 4) &&
            (!tmain.assurance || tmain.piece_assurance)
          ) {
            if (
              tmain.account.is_main_bank == "oui" ||
              tmain.price_selected.type == 4
            ) {
              ret = true;
            } else {
              if (
                tmain.banq_account.nom != "" &&
                tmain.piece_attestation &&
                tmain.piece_cni_titulaire
              ) {
                ret = true;
              }
            }
          }
        }
        if (tmain.subscription_selected.id == 2) {
          if (
            tmain.piece_cni &&
            tmain.piece_justificatif &&
            (tmain.piece_rib || tmain.price_selected.type == 4) &&
            tmain.piece_scolarite &&
            (tmain.account.iban != "" || tmain.price_selected.type == 4) &&
            (tmain.account.bic != "" || tmain.price_selected.type == 4) &&
            (!tmain.assurance || tmain.piece_assurance)
          ) {
            if (
              tmain.account.is_main_bank == "oui" ||
              tmain.price_selected.type == 4
            ) {
              ret = true;
            } else {
              if (
                tmain.banq_account.nom != "" &&
                tmain.piece_attestation &&
                tmain.piece_cni_titulaire
              ) {
                ret = true;
              }
            }
          }
        }
        if (tmain.subscription_selected.id == 3) {
          if (
            tmain.piece_cni &&
            tmain.piece_justificatif &&
            (tmain.piece_rib || tmain.price_selected.type == 4) &&
            tmain.piece_qf &&
            (tmain.account.iban != "" || tmain.price_selected.type == 4) &&
            (tmain.account.bic != "" || tmain.price_selected.type == 4) &&
            (!tmain.assurance || tmain.piece_assurance)
          ) {
            if (
              tmain.account.is_main_bank == "oui" ||
              tmain.price_selected.type == 4
            ) {
              ret = true;
            } else {
              if (
                tmain.banq_account.nom != "" &&
                tmain.piece_attestation &&
                tmain.piece_cni_titulaire
              ) {
                ret = true;
              }
            }
          }
        }
        if (tmain.subscription_selected.id == 4) {
          if (
            tmain.piece_cni &&
            tmain.piece_justificatif &&
            (tmain.piece_rib || tmain.price_selected.type == 4) &&
            tmain.piece_pdmea &&
            (tmain.account.iban != "" || tmain.price_selected.type == 4) &&
            (tmain.account.bic != "" || tmain.price_selected.type == 4) &&
            (!tmain.assurance || tmain.piece_assurance)
          ) {
            if (tmain.account.is_main_bank == "oui") {
              ret = true;
            } else {
              if (
                tmain.banq_account.nom != "" &&
                tmain.piece_attestation &&
                tmain.piece_cni_titulaire
              ) {
                ret = true;
              }
            }
          }
        }
        if (tmain.subscription_selected.id == 5) {
          if (
            tmain.piece_cni &&
            tmain.piece_justificatif &&
            (tmain.piece_rib || tmain.price_selected.type == 4) &&
            tmain.piece_pdmea &&
            tmain.piece_qf &&
            (tmain.account.iban != "" || tmain.price_selected.type == 4) &&
            (tmain.account.bic != "" || tmain.price_selected.type == 4) &&
            (!tmain.assurance || tmain.piece_assurance)
          ) {
            if (tmain.account.is_main_bank == "oui") {
              ret = true;
            } else {
              if (
                tmain.banq_account.nom != "" &&
                tmain.piece_attestation &&
                tmain.piece_cni_titulaire
              ) {
                ret = true;
              }
            }
          }
        }
      }
      return ret;
    },
    material_selected: function () {
      var tmain = this;
      var info = tmain.list_materials.find((x) => x.selected == true);
      if (info == undefined) {
        info = {};
      }
      return info;
    },
    subscription_selected: function () {
      var tmain = this;
      var ret = {};
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        var infret = mat.subscriptions.find((x) => x.selected == true);
        if (infret !== undefined) {
          ret = infret;
        }
      }
      return ret;
    },
    price_selected: function () {
      var tmain = this;
      var ret = {};
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        var infsub = mat.subscriptions.find((x) => x.selected == true);
        if (infsub !== undefined) {
          var infprice = infsub.prices.find((x) => x.selected == true);
          if (infprice !== undefined) {
            ret = infprice;
          }
        }
      }
      return ret;
    },
    agency_selected: function () {
      var tmain = this;
      var info = tmain.list_agencies.find((x) => x.selected == true);
      if (info == undefined) {
        info = {};
      }
      return info;
    },
    lbl_description: function () {
      var tmain = this;
      var ret = "";
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        ret +=
          "1 x " +
          mat.label +
          " pour une durée de " +
          tmain.duree_loc +
          " mois" +
          " - ";
        mat.accessories.forEach(function (acc) {
          if (acc.qties.find((x) => x.selected == true).value > 0) {
            ret +=
              acc.qties.find((x) => x.selected == true).value +
              " x " +
              acc.label +
              " - ";
          }
        });
      }
      return ret.substring(0, ret.length - 3);
    },
    lbl_amount: function () {
      var tmain = this;
      var ret = 0;
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        var infsub = mat.subscriptions.find((x) => x.selected == true);
        if (infsub !== undefined) {
          var infprice = infsub.prices.find((x) => x.selected == true);
          if (infprice !== undefined) {
            ret += infprice.amount;
          }
        }
      }
      return ret;
    },
    lbl_caution_amount: function () {
      var tmain = this;
      var ret = 0;
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        ret = mat.caution;
      }
      return ret;
    },
    lbl_amount_year: function () {
      var tmain = this;
      var ret = 0;
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        var infsub = mat.subscriptions.find((x) => x.selected == true);
        if (infsub !== undefined) {
          var infprice = infsub.prices.find((x) => x.selected == true);
          if (infprice !== undefined) {
            ret += infprice.amount;
          }
        }
      }
      return ret * 11;
    },
    lbl_payment_info_cb: function () {
      var tmain = this;
      var lbl = "";
      var formatter = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      switch (tmain.price_selected.type) {
        case 1:
          lbl =
            formatter.format(tmain.lbl_amount) +
            " à régler maintenant, restant dû le jour du retrait du vélo ";
          lbl += formatter.format(
            Number(tmain.price_selected.total_amount) -
            Number(tmain.price_selected.amount)
          );
          break;
        case 4:
          lbl = formatter.format(tmain.lbl_amount) + " à régler maintenant";
          break;
      }
      return lbl;
    },
    date_start_min: function () {
      var tmain = this;
      var datemin = new Date();
      datemin.setDate(datemin.getDate() + 7);
      return tmain.$moment(datemin).format("YYYY-MM-DD");
    },
    date_start_max: function () {
      var tmain = this;
      var datemax = new Date();
      datemax.setDate(datemax.getDate() + 30);
      return tmain.$moment(datemax).format("YYYY-MM-DD");
    },
    dateoutofbound: function () {
      var tmain = this;
      var today = new Date();
      today =
        today.getMonth() +
        1 +
        "/" +
        String(today.getDate()).padStart(2, "0") +
        "/" +
        today.getFullYear();
      if (
        (tmain.$moment(tmain.date_retrait).diff(tmain.$moment(today), "days") <
          7 &&
          tmain.date_retrait != "") ||
        (tmain.$moment(tmain.date_retrait).diff(tmain.$moment(today), "days") >
          30 &&
          tmain.date_retrait != "")
      ) {
        return false;
      }
      return true;
    },
    date_end: function () {
      var tmain = this;
      var dateend = new Date();
      var mat = tmain.list_materials.find((x) => x.selected == true);
      var sub = mat.subsriptions.find((x) => x.selected == true);
      var duration = sub.type_duration == "mois" ? sub.duration : 12;
      dateend.setMonth(dateend.getMonth() + duration);
      return tmain.$moment(dateend);
    },
  },
  mounted: function () {
    this.$moment.locale("fr");
  },
  created: function () {
    var tmain = this;
    tmain.datenow = tmain.$moment().format("YYYY-MM-DD");
    if (JSON.stringify(tmain.$route.query) != "{}") {
      tmain.refresh_ret(tmain.$route.query);
    } else {
      tmain.refresh_ui();
    }
  },
  methods: {
    refresh_ret: function (params) {
      var tmain = this;
      tmain.loading = true;
      tmain.$router.replace({ query: null });
      tmain.viewdiv = "retour";
      tmain.msgretour = params.state;
      engine.Account_Info(function (pkg) {
        if (pkg.code == -1) {
          tmain.$router.push("/");
        } else {
          tmain.account = pkg.data[0];
          engine.Agency_List(function (pkga) {
            tmain.list_agencies = pkga.data;
            engine.Material_List(function (pkgm) {
              tmain.list_materials = pkgm.data;
              engine.File_Exist("cni", function (pkgp1) {
                tmain.piece_cni = pkgp1.data;
                engine.File_Exist("justificatif", function (pkgp2) {
                  tmain.piece_justificatif = pkgp2.data;
                  engine.File_Exist("rib", function (pkgp3) {
                    tmain.piece_rib = pkgp3.data;
                    engine.File_Exist("caf", function (pkgp4) {
                      tmain.piece_qf = pkgp4.data;
                      engine.File_Exist("scolarite", function (pkgp5) {
                        tmain.piece_scolarite = pkgp5.data;
                        engine.File_Exist("pdmea", function (pkgp6) {
                          tmain.piece_pdmea = pkgp6.data;
                          engine.File_Exist("assurance", function (pkgp7) {
                            tmain.piece_assurance = pkgp7.data;
                            switch (params.state) {
                              case "ok":
                                if (params.ref) {
                                  tmain.msgtitle =
                                    "Votre réservation " +
                                    params.ref +
                                    " a été effectuée avec succès.";
                                  tmain.msgsubtitle =
                                    "Vous allez être contacté par notre équipe pour convenir d'un rendez-vous.";
                                  tmain.loading = false;
                                } else {
                                  tmain.msgtitle =
                                    "Votre réservation " +
                                    params.ref +
                                    " a rencontré une erreur.";
                                  tmain.msgsubtitle =
                                    "Veuillez contacter la Station par e-mail au lovelo@reseau-astuce.fr.";
                                  tmain.loading = false;
                                }

                                break;
                              case "annule":
                                engine.Reservation_Cancel(
                                  params.ref,
                                  function () {
                                    tmain.msgtitle = "Annulation";
                                    tmain.msgsubtitle =
                                      "Vous avez annulé votre demande de réservation de vélo.";
                                    tmain.loading = false;
                                  }
                                );
                                break;
                              case "err":
                                tmain.msgtitle =
                                  "Votre réservation " +
                                  params.ref +
                                  " a rencontré une erreur de paiement.";
                                tmain.msgsubtitle =
                                  "Veuillez contacter la Station par e-mail au lovelo@reseau-astuce.fr.";
                                tmain.loading = false;
                                break;
                            }
                            // });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        }
      });
    },
    refresh_ui: function () {
      var tmain = this;
      tmain.loading = true;
      engine.Account_Info(function (pkg) {
        if (pkg.code != 0) {
          tmain.$router.push("/");
        } else {
          tmain.account = pkg.data[0];
          engine.Agency_List(function (pkga) {
            tmain.list_agencies = pkga.data;
            engine.Material_List(function (pkgm) {
              tmain.list_materials = pkgm.data;
              tmain.loading = false;
              var total_quantity = 0;
              tmain.list_materials.forEach(function (material) {
                total_quantity += material.qty;
              });
              if (total_quantity == 0) {
                tmain.viewdiv = "nostock";
              }
            });
          });
        }
        engine.File_Exist("cni", function (pkgp1) {
          tmain.piece_cni = pkgp1.data;
        });
        engine.File_Exist("justificatif", function (pkgp2) {
          tmain.piece_justificatif = pkgp2.data;
        });
        engine.File_Exist("rib", function (pkgp3) {
          tmain.piece_rib = pkgp3.data;
        });
        engine.File_Exist("caf", function (pkgp4) {
          tmain.piece_qf = pkgp4.data;
        });
        engine.File_Exist("scolarite", function (pkgp5) {
          tmain.piece_scolarite = pkgp5.data;
        });
        engine.File_Exist("pdmea", function (pkgp6) {
          tmain.piece_pdmea = pkgp6.data;
        });
        engine.File_Exist("assurance", function (pkgp7) {
          tmain.piece_assurance = pkgp7.data;
        });
        /*engine.File_Exist('cmuc',function(pkgp4){
          tmain.piece_cmuc = pkgp4.data;
        });
        engine.File_Exist('passmobilite',function(pkgp5){
          tmain.piece_mobilite = pkgp5.data;
        });*/
      });
    },
    refresh_docs: function () {
      var tmain = this;
      engine.File_Exist("cni", function (pkgp1) {
        tmain.piece_cni = pkgp1.data;
        engine.File_Exist("cni_titulaire", function (pkgp12) {
          tmain.piece_cni_titulaire = pkgp12.data;
          engine.File_Exist("justificatif", function (pkgp2) {
            tmain.piece_justificatif = pkgp2.data;
            engine.File_Exist("rib", function (pkgp3) {
              tmain.piece_rib = pkgp3.data;
              engine.File_Exist("caf", function (pkgp4) {
                tmain.piece_qf = pkgp4.data;
                engine.File_Exist("scolarite", function (pkgp5) {
                  tmain.piece_scolarite = pkgp5.data;
                  engine.File_Exist("pdmea", function (pkgp6) {
                    tmain.piece_pdmea = pkgp6.data;
                    engine.File_Exist("assurance", function (pkgp8) {
                      tmain.piece_assurance = pkgp8.data;
                      engine.File_Exist(
                        tmain.banq_account.id_fichier,
                        function (pkgp7) {
                          tmain.piece_attestation = pkgp7.data;
                          var options = {
                            container: ".application",
                            easing: "ease-out",
                            offset: -10,
                            force: true,
                            cancelable: true,
                            x: false,
                            y: true,
                          };
                          if (tmain.account.is_main_bank == "non") {
                            if (
                              tmain.piece_attestation &&
                              tmain.piece_cni_titulaire
                            ) {
                              setTimeout(function () {
                                tmain.$scrollTo("#bloc_recap", 500, options);
                              }, 100);
                            } else {
                              setTimeout(function () {
                                tmain.$scrollTo("#bloc7", 500, options);
                              }, 100);
                            }
                          } else {
                            setTimeout(function () {
                              tmain.$scrollTo("#bloc_recap", 500, options);
                            }, 100);
                          }
                        }
                      );
                    });
                  });
                });
              });
            });
          });
        });
      });
    },
    validS1: function (mat) {
      var tmain = this;
      tmain.duree_loc = "";
      tmain.acceptcguv = false;
      if (mat.qty > 0) {
        tmain.list_mois.forEach((s) => (s.selected = false));
        tmain.list_materials.forEach((mat) => {
          mat.selected = false;
          mat.accessories.forEach((acc) => {
            acc.selected = false;
            acc.qties.forEach((q) => (q.selected = false));
            acc.qties[0].selected = true;
          });
        });
        tmain.qtycumul = 0;
        mat.selected = true;
        var options = {
          container: ".application",
          easing: "ease-out",
          offset: -10,
          force: true,
          cancelable: true,
          x: false,
          y: true,
        };
        tmain.step = 2;
        setTimeout(function () {
          tmain.$scrollTo("#bloc2", 500, options);
        }, 100);
      }
    },
    validS1acc: function (acc, qtyselect) {
      var tmain = this;
      tmain.date_retrait = "";
      acc.qties.forEach((x) => (x.selected = false));
      tmain.list_mois.forEach((s) => (s.selected = false));
      acc.selected = true;
      qtyselect.selected = true;
      var qtycumul = 0;
      tmain.material_selected.accessories
        .filter((x) => x.selected == true)
        .forEach(function (tacc) {
          tacc.qties
            .filter((q) => q.selected == true)
            .forEach(function (qt) {
              qtycumul += qt.value;
            });
        });

      if (
        tmain.material_selected.accessories.filter((x) => x.selected == true)
          .length == tmain.material_selected.accessories.length
      ) {
        if (qtycumul > tmain.material_selected.qtymaxaccessories) {
          qtyselect.selected = false;
          tmain.material_selected.accessories
            .filter((x) => x.selected == true)
            .forEach(function (tacc) {
              if (tacc.qties.filter((q) => q.selected == true).length == 0) {
                tacc.selected = false;
                tmain.step = 1;
              }
            });
          alert("trop de qte selectionné");
        } else {
          tmain.step = 2;
          var options = {
            container: ".application",
            easing: "ease-out",
            offset: -10,
            force: true,
            cancelable: true,
            x: false,
            y: true,
          };
          setTimeout(function () {
            tmain.$scrollTo("#bloc2", 500, options);
          }, 100);
        }
      }
    },
    validS2: function (duree_location) {
      var tmain = this;
      tmain.material_selected.subscriptions.forEach(
        (s) => (s.selected = false)
      );
      tmain.list_mois.forEach((s) => (s.selected = false));
      duree_location.selected = true;
      if (duree_location.value > 0 && duree_location.value < 13) {
        tmain.duree_loc = duree_location.value;
        tmain.step = 3;
        var options = {
          container: ".application",
          easing: "ease-out",
          offset: -10,
          force: true,
          cancelable: true,
          x: false,
          y: true,
        };
        setTimeout(function () {
          tmain.$scrollTo("#bloc3", 500, options);
        }, 100);
      } else {
        tmain.step = 2;
      }
    },
    validS3: function (subs) {
      var tmain = this;
      tmain.material_selected.subscriptions.forEach(
        (s) => (s.selected = false)
      );
      subs.selected = true;
      subs.prices.forEach((s) => (s.selected = false));
      var debut_loc = 0;
      var fin_loc = 0;
      if (tmain.duree_loc < 3) {
        debut_loc = tmain.duree_loc;
      } else {
        debut_loc = 3;
        fin_loc = tmain.duree_loc - 3;
      }

      var i;
      var tot_debut = 0;
      var tot_fin = 0;
      var tot = 0;
      var premier_mois = 0;
      if (!tmain.assurance) {
        tot_debut = Number(subs.tarif_haute) * Number(debut_loc);
        tot_fin = Number(subs.tarif_basse) * Number(fin_loc);
        tot_debut = Number(tot_debut).toFixed(2);
        tot_fin = Number(tot_fin).toFixed(2);
        tot = Number(tot_debut) + Number(tot_fin);
        premier_mois = subs.tarif_haute;
      } else {
        tot_debut =
          Number(subs.tarif_haute) * Number(debut_loc) +
          Number(subs.montant_assu) * Number(debut_loc); // l'assurance est en négative
        tot_fin =
          Number(subs.tarif_basse) * Number(fin_loc) +
          Number(subs.montant_assu) * Number(fin_loc); // l'assurance est en négative
        tot = tot_fin + tot_debut;
        premier_mois = Number(subs.tarif_haute) + Number(subs.montant_assu); // l'assurance est en négative
      }

      for (i = 0; i < subs.prices.length; i++) {
        subs.prices[i].total_amount = tot;
        subs.prices[i].assu_mois = subs.montant_assu;
        if (subs.prices[i].type >= 4) {
          subs.prices[i].amount = tot;
        } else {
          subs.prices[i].amount = premier_mois;
        }
      }
      tmain.step = 4;
      tmain.list_prices = [];
      tmain.list_prices = subs.prices;
      var options = {
        container: ".application",
        easing: "ease-out",
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      setTimeout(function () {
        tmain.$scrollTo("#bloc4", 500, options);
      }, 100);
    },
    validS4: function (p) {
      var tmain = this;
      tmain.subscription_selected.prices.forEach((s) => (s.selected = false));
      p.selected = true;
      tmain.montant_tot = tmain.list_prices[0];
      if (p.id == 3) {
        tmain.montant_mois = tmain.list_prices[1];
      } else {
        tmain.montant_mois = tmain.list_prices[0];
      }
      //comptant oui/non
      if (p.id == 1) {
        tmain.flag_comptant = "non";
      } else {
        tmain.flag_comptant = "oui";
      }
      tmain.step = 5;
      tmain.delivery_place_selected = undefined;
      var options = {
        container: ".application",
        easing: "ease-out",
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      setTimeout(function () {
        tmain.$scrollTo("#bloc5", 500, options);
      }, 100);
    },
    validS5: function (delivery_place) {
      let tmain = this;
      tmain.list_agencies.forEach((s) => (s.selected = false));
      if (delivery_place) delivery_place.selected = true;
      tmain.delivery_place_selected = delivery_place;
      tmain.step = 6;
      var options = {
        container: ".application",
        easing: "ease-out",
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };

      setTimeout(function () {
        tmain.$scrollTo("#bloc6", 500, options);
      }, 100);
    },

    modifAssur: function (assu) {
      var tmain = this;
      tmain.assurance = assu;
      tmain.subscription_selected.prices.forEach((s) => (s.selected = false));
      tmain.material_selected.subscriptions.forEach(
        (s) => (s.selected = false)
      );
    },
    editprofile: function () {
      var tmain = this;
      tmain.ctrldataform = {
        title: "Modifier vos informations personnelles",
        controls: [
          {
            datatype: "text",
            datavalue: tmain.account.iban,
            databind: "iban",
            description: "IBAN (la longueur doit être de 27 caractères)",
          },
          {
            datatype: "text",
            datavalue: tmain.account.bic,
            databind: "bic",
            description: "BIC",
            display: "none",
          },
          {
            datatype: "select",
            datavalue: tmain.account.is_main_bank,
            databind: "is_main_bank",
            description: "Etes-vous titulaire du compte bancaire ?",
            datalist: [
              { value: "oui", label: "Oui" },
              { value: "non", label: "Non" },
            ],
          },
        ],
        fxback: function () {
          tmain.ctrldataform = {};
        },
        fxsave: function (o) {
          if (o.iban) {
            tmain.account.iban = o.iban;
            let iban = o.iban.replace(/ /g, "").toUpperCase();
            let fourFistDigit = iban.substring(0, 4);
            let newIban = iban.substring(4, iban.length) + fourFistDigit;
            let ibanWithoutLetters = "";
            for (let i = 0; i < newIban.length; i++) {
              if (
                newIban[i].charCodeAt(0) >= 65 &&
                newIban[i].charCodeAt(0) <= 90
              ) {
                ibanWithoutLetters += newIban[i].charCodeAt(0) - 55;
              } else {
                ibanWithoutLetters += newIban[i];
              }
            }
            if (tmain.checkIBAN(ibanWithoutLetters) == 1) {
              tmain.iban_verification(
                function (newIbanData) {
                  engine.Account_Edit_Bank(newIbanData, function () {
                    engine.Account_Info(function (pkg) {
                      if (pkg.code == -1) {
                        tmain.$router.push("/");
                      } else {
                        // tmain.account = pkg.data[0];
                        // tmain.iban_verification();
                        tmain.ctrldataform = {};
                        tmain.refresh_docs();
                      }
                    });
                  });
                  tmain.ctrldataform = {};
                }
              );
              // engine.Iban_verification(iban, function (data) {
              //   console.log('Iban_verification', data)
              //   if (data.code == 0) {
              //     console.log(0);
              //     if (data.data.bic) {
              //       console.log(1);
              //       bic = data.data.bic
              //       tmain.account.bic = data.data.bic;
              //     }
              //     tmain.iban_information = {
              //       iban: iban,
              //       bic: bic,
              //       valid: true,
              //     };
              //     // tmain.account.bic = bic_value.data.bic;
              //     // o.bic = bic_value.data.bic;
              //     engine.Account_Edit_Bank({ iban: iban, bic: bic }, function () {
              //       engine.Account_Info(function (pkg) {
              //         if (pkg.code == -1) {
              //           tmain.$router.push("/");
              //         } else {
              //           tmain.account = pkg.data[0];
              //           // tmain.iban_verification();
              //           tmain.refresh_docs();
              //           tmain.ctrldataform = {};
              //         }
              //       });
              //     });
              //     tmain.ctrldataform = {};
              //   } else {
              //     console.log(2);
              //     tmain.iban_information = { valid: false };
              //     tmain.account.bic = "";
              //   }
              // });
            } else {
              tmain.iban_information = { valid: false };
              engine.Account_Edit_Bank({ iban: o.iban, bic: "" }, function () {
                engine.Account_Info(function (pkg) {
                  if (pkg.code == -1) {
                    tmain.$router.push("/");
                  } else {
                    tmain.account = pkg.data[0];
                    tmain.refresh_docs();
                    tmain.ctrldataform = {};
                  }
                });
              })
              // tmain.iban_information = { valid: false };
              // tmain.refresh_docs();
              // tmain.ctrldataform = {};
            }
          }
        },
      };
    },
    iban_verification(callback) {

      var tmain = this;
      if (tmain.account.iban) {
        let iban = tmain.account.iban.replace(/ /g, "").toUpperCase();
        let fourFistDigit = iban.substring(0, 4);
        let newIban = iban.substring(4, iban.length) + fourFistDigit;
        let ibanWithoutLetters = "";
        for (let i = 0; i < newIban.length; i++) {
          if (
            newIban[i].charCodeAt(0) >= 65 &&
            newIban[i].charCodeAt(0) <= 90
          ) {
            ibanWithoutLetters += newIban[i].charCodeAt(0) - 55;
          } else {
            ibanWithoutLetters += newIban[i];
          }
        }
        if (tmain.checkIBAN(ibanWithoutLetters) === 1) {
          engine.Iban_verification(iban, function (data) {
            let bic = ""
            if (data.code == 0) {
              if (data.data.bic) {
                bic = data.data.bic
                tmain.account.bic = data.data.bic;
              }
              tmain.iban_information = {
                iban: iban,
                bic: bic,
                valid: true,
              };
              // tmain.account.bic = bic_value.data.bic;
              // o.bic = bic_value.data.bic;
              engine.Account_Edit_Bank({ iban: iban, bic: bic }, function () {
                engine.Account_Info(function (pkg) {
                  if (pkg.code == -1) {
                    tmain.$router.push("/");
                  } else {
                    tmain.account = pkg.data[0];
                    // tmain.iban_verification();
                    tmain.refresh_docs();
                    tmain.ctrldataform = {};
                  }
                });
              });
              tmain.ctrldataform = {};
              // }
              // if (bic_value.data.bic) {
              //   tmain.iban_information = {
              //     iban: iban,
              //     bic: bic_value.data.bic,
              //     valid: true,
              //   };
              //   tmain.account.bic = bic_value.data.bic;
            } else {
              tmain.iban_information = { valid: false };
              tmain.account.bic = "";
            }
            tmain.ctrldataform = {};
            tmain.refresh_docs();
            if (callback) callback({ iban, bic })
          });
        } else {
          tmain.iban_information = { valid: false };
        }
      }
    },
    checkIBAN(iban) {
      const parts = iban.match(/.{1,6}/g);
      return parts.reduce((prev, curr) => Number(prev + curr) % 97, "");
    },
    editbanqaccount: function () {
      var tmain = this;
      tmain.ctrldataform = {
        title: "Indiquer les informations du titulaire du compte bancaire",
        controls: [
          {
            datatype: "text",
            datavalue: tmain.banq_account.prenom,
            databind: "prenom",
            description: "Prénom *",
            condvalue: false,
            condmsg: "Le prénom est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.nom,
            databind: "nom",
            description: "Nom de famille *",
            condvalue: false,
            condmsg: "Le nom de famille est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.adresse,
            databind: "adresse",
            description: "Adresse *",
            condvalue: false,
            condmsg: "L'adresse est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.cp,
            databind: "cp",
            description: "Code postal *",
            condvalue: false,
            condmsg: "Le code postal est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.ville,
            databind: "ville",
            description: "Ville *",
            condvalue: false,
            condmsg: "La ville est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.telephone,
            databind: "telephone",
            description: "Téléphone fixe *",
            condvalue: false,
            condmsg: "Le numéro de téléphone est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.email,
            databind: "email",
            description: "Courriel *",
            condvalue: false,
            condmsg: "Le courriel est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.lien_parente,
            databind: "lien_parente",
            description: "Lien de parenté *",
            condvalue: false,
            condmsg: "Le lien de parenté est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
        ],
        fxback: function () {
          tmain.ctrldataform = {};
        },
        fxsave: function (o) {
          tmain.banq_account.prenom = o.prenom;
          tmain.banq_account.nom = o.nom;
          tmain.banq_account.adresse = o.adresse;
          tmain.banq_account.cp = o.cp;
          tmain.banq_account.ville = o.ville;
          tmain.banq_account.telephone = o.telephone;
          tmain.banq_account.email = o.email;
          tmain.banq_account.lien_parente = o.lien_parente;
          tmain.refresh_docs();
          tmain.ctrldataform = {};
        },
      };
    },
    editbanqfile: function () {
      var tmain = this;
      tmain.account.file_attestation = "";
      tmain.ctrldataform = {
        title: "Fournir le formulaire de procuration",
        controls: [
          {
            datatype: "link",
            description:
              "Etape 1 : Télécharger le formulaire vierge de procuration",
            link: "",
          },
          {
            datatype: "file",
            databind: "file_attestation",
            description:
              "Etape 2 : Envoyer le formulaire de procuration complété et signé",
            condvalue: false,
            condmsg: "Le fichier doit être au format PDF ou image",
            condfx: function (val) {
              return (
                val.type == "application/pdf" ||
                val.type.indexOf("png") > -1 ||
                val.type.indexOf("jpg") > -1 ||
                val.type.indexOf("jpeg") > -1
              );
            },
          },
        ],
        fxback: function () {
          tmain.ctrldataform = {};
        },
        fxsave: function (ctrl) {
          engine.File_Upload(
            ctrl.file_attestation,
            tmain.banq_account.id_fichier,
            function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            }
          );
        },
      };
    },
    downloadFile: function (doctype) {
      var tmain = this;
      if (doctype == "attestation") {
        doctype = tmain.banq_account.id_fichier;
      }
      engine.File_Download(doctype, function (pkgfile) {
        var ctype = pkgfile.headers.map["content-type"];
        var extname = ".pdf";
        if (ctype[0] == "image/png") {
          extname = ".png";
        }
        if (ctype[0] == "image/jpg") {
          extname = ".jpg";
        }
        if (ctype[0] == "image/jpeg") {
          extname = ".jpeg";
        }
        var fileURL = window.URL.createObjectURL(new Blob([pkgfile.data]));
        var download_link = document.createElement("a");
        download_link.href = fileURL;
        download_link.download = doctype + extname;
        download_link.target = "_blank";
        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);
      });
    },
    uploadFile: function (doctype) {
      var tmain = this;
      tmain.account.file_cni = "";
      tmain.account.file_cni_titulaire = "";
      tmain.account.file_justificatif = "";
      tmain.account.file_rib = "";
      tmain.account.file_cmuc = "";
      tmain.account.file_passmobilite = "";
      tmain.account.file_attestation = "";
      tmain.account.file_cmuc = "";
      tmain.account.file_passmobilite = "";
      tmain.account.file_attestation = "";

      if (doctype == "cni") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_cni",
              description: "CNI / Passeport (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(ctrl.file_cni, "cni", function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            });
          },
        };
      }
      if (doctype == "cni_titulaire") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_cni_titulaire",
              description: "CNI / Passeport (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(
              ctrl.file_cni_titulaire,
              "cni_titulaire",
              function () {
                tmain.ctrldataform = {};
                tmain.refresh_docs();
              }
            );
          },
        };
      }
      if (doctype == "justificatif") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_justificatif",
              description: "Justificatif de domicile (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(
              ctrl.file_justificatif,
              "justificatif",
              function () {
                tmain.ctrldataform = {};
                tmain.refresh_docs();
              }
            );
          },
        };
      }
      if (doctype == "rib") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_rib",
              description:
                "Relevé d'Identité Bancaire (RIB - au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(ctrl.file_rib, "rib", function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            });
          },
        };
      }
      if (doctype == "caf") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_qf",
              description:
                "attestation CAF de moins d'un mois pour les QF < 700",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(ctrl.file_qf, "caf", function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            });
          },
        };
      }
      if (doctype == "scolarite") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_scolarite",
              description: "Justificatif de scolarité",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(ctrl.file_scolarite, "scolarite", function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            });
          },
        };
      }
      if (doctype == "pdmea") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_pdmea",
              description:
                "Justificatif d'appartenance à une entreprise signataire d'un PDME-A en vigueur dans la MRN",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(ctrl.file_pdmea, "pdmea", function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            });
          },
        };
      }
      if (doctype == "assurance") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_assurance",
              description:
                "Justificatif d'assurance incluant la responsabilité civile et le vol",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(ctrl.file_assurance, "assurance", function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            });
          },
        };
      }
      if (doctype == "passmobilite") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "html",
              description:
                '<p style="border:solid 2px red;padding:5px;">ATTENTION : Pour bénéficier de ce tarif à 24€/mois (264€/an), vous devez être détenteur d\'un <a target="_blank" href="https://www.lepilote.com/fr/tarifs/7/Fare/pass-integral-annuel-aix-marseille-provence/199?PartnerId=10&Frequency=THM_FARE_6">Pass Integral Annuel à 68€/mois</a> ou d\'un <a target="_blank" href="https://www.lecaraixmarseille.com/pass-metropole">Pass Métropole à 30,40€/mois</a>, en cours de validité. Aucun autre abonnement n\'est éligible à ce tarif.<br/><br/>NB : Seule l\'attestation d\'abonnement Pass Intégral ou Pass Métropole sera acceptée, la photo de votre carte de transport n\'a pas valeur de justificatif. Vous pouvez demander une attestation d\'abonnement sur le site RTM <a target="_blank" href="https://www.rtm.fr/attestation-abonnement">https://www.rtm.fr/attestation-abonnement</a>.</p>',
            },
            {
              datatype: "file",
              databind: "file_passmobilite",
              description:
                "Attestation Abonnement Intégral Annuel ou Pass Métropole (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(
              ctrl.file_passmobilite,
              "passmobilite",
              function () {
                tmain.ctrldataform = {};
                tmain.refresh_docs();
              }
            );
          },
        };
      }
      if (doctype == "attestation") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_attestation",
              description: "Procuration du titulaire du compte bancaire",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(
              ctrl.file_attestation,
              tmain.banq_account.id_fichier,
              function () {
                tmain.ctrldataform = {};
                tmain.refresh_docs();
              }
            );
          },
        };
      }
    },
    payment_start: function () {
      var tmain = this;
      if (tmain.account.fin_contrats >= tmain.datenow) {
        alert("Reservation impossible, vous en avez déjà une en cours");
        tmain.$router.push("/board");
      } else {
        tmain.loading = true;
        var pkgp = {
          materials: tmain.list_materials,
          agencies: tmain.delivery_place_selected.ID,
          prices: tmain.list_prices,
          duree_location: tmain.duree_loc,
          assurance: tmain.assurance,
          flag_comptant: tmain.flag_comptant,
          options: {
            is_main_bank: tmain.account.is_main_bank,
            banq_account: tmain.banq_account,
          },
        };
        engine.Reservation_Create(pkgp, function (pkgcreate) {
          if (pkgcreate.code != -1) {
            var json = {
              billing: {
                addressLine1: tmain.account.address,
                city: tmain.account.city,
                postalCode: tmain.account.zipcode,
              },
            };
            //TODO gérer la casse
            if (tmain.account.country == "FRANCE") {
              json.billing.country = "FR";
            }
            if (tmain.account.address2 != "") {
              json.billing.addressLine2 = tmain.account.address2;
            }
            tmain.form_contexte = btoa(JSON.stringify(json));
            var datenow = tmain.$moment().format();
            var t1 = datenow.split("+");
            var t2 = t1[0].split("T");
            var tabl = t2[0].split("-");
            datenow = tabl[2] + "/" + tabl[1] + "/" + tabl[0] + ":" + t2[1];
            var reference = pkgcreate.data.replace("-", "");
            var paquet = [
              { label: "TPE", value: "6481836" },
              { label: "contexte_commande", value: tmain.form_contexte },
              { label: "date", value: datenow },
              { label: "lgue", value: "FR" },
              { label: "mail", value: tmain.account.email },
              { label: "montant", value: tmain.lbl_amount + "EUR" },
              { label: "reference", value: reference },
              { label: "societe", value: "tcarlovelo" },
              {
                label: "url_retour_err",
                value:
                  "https://location-lovelo.myastuce.fr/#/reservation?state=err&ref=" +
                  reference,
                //  value:
                //     "https://location-lovelo.reseau-astuce.fr/#/reservation?state=err&ref=" +
                //     reference,
              },
              {
                label: "url_retour_ok",
                value:
                  "https://location-lovelo.myastuce.fr/#/reservation?state=ok&ref=" +
                  reference,
                // value:
                // "https://location-lovelo.reseau-astuce.fr/#/reservation?state=ok&ref=" +
                // reference,
              },
              { label: "version", value: "3.0" },
            ];
            engine.GetHmac(paquet, function (pkg) {
              if (pkg.code == 0) {
                var form = document.createElement("form");
                form.setAttribute("method", "post");
                form.setAttribute("action", pkg.data.website);
                form.setAttribute("target", "");
                for (var i = 0; i < paquet.length; i++) {
                  tmain.createHiddenInput(
                    form,
                    paquet[i].label,
                    paquet[i].value
                  );
                }
                tmain.createHiddenInput(form, "MAC", pkg.data.hmac);
                document.body.appendChild(form);
                form.submit();
              }
            });
          } else {
            alert("erreur! impossible de créer la réservation");
          }
        });
      }
    },
    createHiddenInput: function (form, label, value) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", label);
      hiddenField.setAttribute("value", value);
      form.appendChild(hiddenField);
    },
    backAccount: function () {
      var tmain = this;
      tmain.$router.push("/board");
    },
    goRDV: function () {
      var download_link = document.createElement("a");
      download_link.href = "";
      download_link.target = "_blank";
      document.body.appendChild(download_link);
      download_link.click();
      document.body.removeChild(download_link);
    },
    goNOSTOCK: function () {
      var tmain = this;
      engine.Reservation_Nostock(tmain.noStockMaterial, function () {
        tmain.viewdiv = "nostock_valid";
      });
    },
    goViewNOSTOCK: function (material) {
      var tmain = this;
      tmain.noStockMaterial = material
      tmain.viewdiv = "nostock";
    },
  },
};
</script>
<style></style>
